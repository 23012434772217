<template>
  <div class="page adjust_width options">
    
    <bo_retour name="bo_produits"></bo_retour>
    
    <title_cat>Editer un supplément / options</title_cat>

    <div @click="save" class="plus save shadow">Sauvegarder</div>
    
    <div class="int shadow">
      
      <el-form ref="form" label-width="120px" label-position="top">
        
        <el-form-item label="Titre - Pour vous">
          <el-input v-model="name"></el-input>
        </el-form-item>
        
        
        <el-form-item label="Intitulé de l'option">
          <el-input v-model="type"></el-input>
<!--          <div class="col">-->
<!--            <el-select v-model="type" placeholder="Select">-->
<!--              <el-option-->
<!--                  v-for="item in cities"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--                <span style="float: left">{{ item.label }}</span>-->
<!--                <span style="display: flex; justify-content: flex-end; align-items: center; height: 100%;"><img style="height: 28px;" class="ico" :src="'/img/ico/food/' + item.value + '.png'" alt=""></span>-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </div>-->
        </el-form-item>
        
        
        <el-form-item label="Conditions">
          <div class="col">
            
            <el-select v-model="conditions" placeholder="Select">
              <el-option
                  v-for="item in conditions_select"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="display: flex; justify-content: flex-end; align-items: center; height: 100%;"><img style="height: 28px;" class="ico" :src="'/img/ico/food/' + item.value + '.png'" alt=""></span>
              </el-option>
            </el-select>
  
            <el-input-number v-if="conditions === 'obligatoire_multiple_set' || conditions === 'obligatoire_multiple_limites' || conditions === 'not_multiple_limites'" v-model="conditions_nb" :min="1" :max="10"></el-input-number>
          </div>
        </el-form-item>
      </el-form>

      
    </div>


    <div @click="add" class="plus shadow">+ Ajouter une option</div>
    
    <div class="categorie shadow" v-for="(n, k) in data" :key="k">
      <div class="left">
        <input type="text" class="name" placeholder="Nom de l'option" v-model="n.name">
        <input type="text" class="name" placeholder="Détails" v-model="n.details">
      </div>
      <div class="right">
        <div class="ico" @click="up(k)"><i class="el-icon-caret-top"></i></div>
        <div class="ico" @click="down(k)"><i class="el-icon-caret-bottom"></i></div>
        <div class="prix">
          <input type="text" v-model="n.price" placeholder="0.00">
<!--          oninput="this.value=this.value.replace(/[^0-9.,]/g,'');"-->
          <span>€</span>
        </div>

        <el-dropdown trigger="click" @command="handleCommand">
          <div class="btn_navigation">
            <i class="el-icon-more"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="'delete-' + k" value="test" icon="el-icon-delete">Supprimer</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
<!--        <el-radio v-model="radio1" label="1" border>Recommandé</el-radio>-->
      </div>
    </div>
    
    
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: "edit",
  data() {
    return {
      data: [],
      cities: [{
        value: 'viande',
        label: 'Viande'
      }, {
        value: 'sauce',
        label: 'Sauce'
      }, {
        value: 'accompagnement',
        label: 'Accompagnement'
      }, {
        value: 'soda',
        label: 'Boisson'
      }, {
        value: 'supplement',
        label: 'Supplements'
      }],
      conditions_select: [{
        value: 'obligatoire',
        label: 'Obligatoire'
      }, {
        value: 'obligatoire_multiple',
        label: 'Obligatoire et quantité sans limites'
      }, {
        value: 'obligatoire_multiple_limites',
        label: 'Obligatoire et quantité limitée'
      }, {
        value: 'obligatoire_multiple_set',
        label: 'Obligatoire et quantité définie'
      }, 
      //   {
      //   value: 'not_obligatoire',
      //   label: 'Non Obligatoire'
      // }, 
        {
        value: 'not_multiple',
        label: 'Non Obligatoire et quantité sans limites'
      }, {
        value: 'not_multiple_limites',
        label: 'Non Obligatoire et quantité limitée'
      }],
      name: null,
      conditions: 'not_multiple',
      conditions_nb: 1,
      type: null,
    }
  },
  created() {
    if(this.$route.name === 'bo_options_edit') {
      this.$http.get('/bo/boutique/options').then((response) => {
        let data = _.filter(response.data, {'id': parseInt(this.$route.params.option_id)})[0]
        this.data = data.data
        this.name = data.name
        this.type = data.type
        this.conditions = data.conditions
        this.conditions_nb = data.conditions_nb
      })
    }
  },
  methods: {
    add() {
      this.data.push({ name: '' })
    },
    handleCommand(command) {
      command = command.split('-')
      if(command[1])
        command[1] = parseInt(command[1])
      
      if(command[0] === 'delete') 
        this.data.splice(command[1], 1)
    },
    save() {
      // this.data = this.data.filter(d => d.name);
      let r_new = []
      for(let n of this.data) {
        if(n.name.length) {
          if(n.price === '0' || n.price === '00') n.price = null
          if(n.price) {
            n.price = n.price.replace(',', '.')
            n.price = n.price.replace(/[^0-9.]/g,'')
          }
          r_new.push(n)
        }
      }
      
      this.data = r_new
      
      if(!this.name || !this.type || !this.conditions) return
      
      if(this.$route.name === 'bo_options_add') {
        let r = {
          data: this.data,
          name: this.name,
          type: this.type,
          conditions: this.conditions,
          conditions_nb: this.conditions_nb,
        }
        this.$http.post('/bo/boutique/options', r).then((v) => {
          this.$notify({
            title: 'Enregistré avec succès',
            offset: this.$notifyOffest,
            type: 'success'
          });
          this.$store.dispatch("boutique_bo/sync");
          this.$store.dispatch("boutique/sync_force", this.$store.state.user.user.boutique);
          this.$router.push({ name: 'bo_options_edit', params: { option_id: v.data.id } })
        })
        console.log('add')
      }
      else if(this.$route.name === 'bo_options_edit') {
        let r = {
          id: this.$route.params.option_id,
          data: this.data,
          name: this.name,
          type: this.type,
          conditions: this.conditions,
          conditions_nb: this.conditions_nb,
        }
        this.$http.put('/bo/boutique/options', r).then(() => {
          this.$notify({
            title: 'Enregistré avec succès',
            offset: this.$notifyOffest,
            type: 'success'
          });
          this.$store.dispatch("boutique_bo/sync");
          this.$store.dispatch("boutique/sync_force", this.$store.state.user.user.boutique);
        })
      }
    },
    up(i) {
      this.data = this.array_move(this.data, i, i - 1)
    },
    down(i) {
      if(this.data.length > i + 1) 
        this.data = this.array_move(this.data, i, i + 1)
    },
    array_move(arr, old_index, new_index) {
      console.log(arr, old_index, new_index)
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing purposes
    }
  }
}
</script>

<style scoped>

  .plus { background: #fff; height: 40px; border-radius: 8px; display: inline-flex; align-items: center; justify-content: center; font-size: 18px; padding: 0 16px; margin-bottom: 16px;
    cursor: pointer; margin-left: 16px; margin-top: 16px;
  }
  .plus.save { margin-top: 0; }

  .categorie { background: #fff; display: flex; height: 60px; align-items: center; justify-content: space-between; font-size: 16px; padding: 0 14px; margin-bottom: 2px; border-radius: 2px;
    transition: all .3s ease; }
  .categorie .left { height: 100%; width: 70%; display: flex; flex-direction: column; justify-content: center; }
  .categorie .left input { height: 20px; }
  .categorie .left input:nth-child(1) { font-weight: 500; }
  .categorie .left input:nth-child(2) { font-size: 14px; color: #666 }
  .categorie .right { height: 70%; display: flex; align-items: center }
  .categorie .right .ico { display: inline-flex; align-items: center; justify-content: center; border-radius: 16px; color: var(--black-sub); height: 20px; width: 20px; background: #f5f5f5; 
    margin-right: 12px; cursor: pointer }
  .categorie input { border: none; height: 100%; outline: none }
  .categorie input.name { width: 100%; }
  .categorie .prix{ height: 100%; background: #f5f5f5; color: var(--black-sub); padding: 0 16px; font-weight: 400; display: flex; align-items: center }
  .categorie .prix input { background: none; width: 40px; }
  .categorie .prix span { padding-top: 1px; }
  /*.categorie .prix input::placeholder { color: var(--black-sub) }*/
  .categorie input::placeholder { color: var(--black-sub) }
  .categorie .btn_navigation { width: 30px; display: flex; align-items: center; justify-content: flex-end; cursor: pointer }
  .categorie .btn_navigation i { transform: rotate(90deg); font-size: 22px; }
  
  .int { padding: 6px 16px; background: #fff; }

  .col { display: flex; }
  /*.col .el-select { width: 60% !important; margin-right: 8px; }*/
  .col .el-input-number { width: 40% !important; min-width: 40%; margin-left: 8px; }
  

  @media only screen and (min-width: 800px) {
    .int { background: #fff; padding: 6px 24px; margin-bottom: 16px; }
    .col { width: 400px; }
    
    .plus { margin-left: 0; }
  }
  
</style>